export default [
  {
    path: '/',
    component: 'layouts/empty',
    redirect: {
      enterprise: '/account/settings',
      investor: '/account/settings',
    },
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'otc-payment.title',
        Icon: 'AccountCircleOutlinedIcon',
        path: '/otc-payment',
        redirect: {
          default: '/otc-payment/payment',
          enterprise: '/otc-payment/payment',
          investor: '/otc-payment/payment',
        },
        authorize: ['investor', 'enterprise'],
        breadcrumbLink: false,
        order: 1,
        routes: [
          {
            title: 'title.otc.dashboard',
            path: '/otc-payment/payment',
            component: 'otc-payment/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
        ],
      },
    ],
  },
];
